<template>
  <div class="d-flex align-items-center justify-content-center full-height">
    <div class="d-flex align-items-center flex-column">
      <b-spinner></b-spinner>

      <div class="mt-4 loader-text">
        Connexion à Shopify, veuillez patienter...
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/http'
import userData from '@/mixins/user-data'

export default {
  mixins: [userData],
  methods: {
    validateAuthentication () {
      if (!this.isUserAuthenticated) {
        localStorage.setItem('post_login_url', window.location.href)
        this.$router.push('/login?msg=requireAuthToConnectStore')
        return false
      }
      return true
    },
    async handleCallback () {
      if (!this.validateAuthentication()) {
        return
      }

      const isValid = this.isRequestValid()

      if (!isValid) {
        this.$toasted.error('Nous ne pouvons authentifier la requête. Veuillez réessayer.')
        this.$router.push({ name: 'connect.shopify' })
        return
      }

      localStorage.removeItem('shopify_oauth_nonce')

      try {
        // Get access token from oauth authorization code
        const { data } = await http.post('/v1/connect/shopify/access-token', {
          shopName: this.getShopName(this.$route.query.shop),
          authQuery: this.$route.query,
          connectedStoreId: this.$route.params.connectedStoreId
        })

        // Save access token in connected store entity
        await http.put(`/v1/stores/${this.currentStoreId}/connected-stores/shopify`, {
          ...data,
          shop: this.$route.query.shop
        })

        this.$router.push('/connected-store')
      } catch (e) {
        console.error(e)

        this.$toasted.error('Erreur lors de la connexion à Shopify. Veuillez réessayer.')
        this.$router.push({ name: 'connect.shopify' })
      }
    },
    isRequestValid () {
      const { state, shop } = this.$route.query

      if (state !== localStorage.getItem('shopify_oauth_nonce')) {
        return false
      }

      return /[a-zA-Z0-9][a-zA-Z0-9-]*\.myshopify\.com/.test(shop)
    },
    getShopName (shopHostname = '') {
      const dotIndex = shopHostname.indexOf('.')
      return dotIndex > 0 ? shopHostname.substring(0, dotIndex) : ''
    }
  },
  created () {
    this.handleCallback()
  }
}
</script>

<style lang="scss" scoped>
.loader-text {
  font-weight: 500;
  font-size: 16px;
}
</style>
