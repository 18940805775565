<template>
  <div class="d-flex align-items-center justify-content-center full-height">
    <div class="d-flex align-items-center flex-column">
      <b-spinner></b-spinner>

      <div class="mt-4 loader-text">
        Redirection vers Shopify, veuillez patienter...
      </div>
    </div>
  </div>
</template>

<script>
import userData from '@/mixins/user-data'
import http from '@/utils/http'

export default {
  mixins: [userData],
  data () {
    return {
      inputShopName: ''
    }
  },
  computed: {
    connectedStoreId () {
      return this.$route.params.connectedStoreId
    },
    receivedShopName () {
      const { shop } = this.$route.query

      if (shop) {
        const dotIndex = this.$route.query.shop.indexOf('.')
        return dotIndex > 0 ? shop.substring(0, dotIndex) : ''
      }

      return null
    }
  },
  methods: {
    async beginOAuth (shopName) {
      const apiKey = await this.getApiKey()
      const scopes = 'read_products,read_product_listings,read_inventory,read_price_rules,read_discounts'
      const redirectUri = `${window.location.origin}/connect/shopify/${this.connectedStoreId}/callback`
      const accessMode = '' // Note: leave blank for 'offline access'
      const nonce = this.generateRandomString(12)

      localStorage.setItem('shopify_oauth_nonce', nonce)
      window.location.href = `https://${shopName}.myshopify.com/admin/oauth/authorize?client_id=${apiKey}&scope=${scopes}&redirect_uri=${redirectUri}&state=${nonce}&grant_options[]=${accessMode}`
    },
    generateRandomString (length = 8) {
      let string = ''
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      for (let i = 0; i < length; i++) {
        string += alphabet.charAt(Math.floor(Math.random() * alphabet.length))
      }
      return string
    },
    async getApiKey () {
      const { data } = await http.get(`/v1/stores/${this.currentStoreId}/connected-stores/shopify`)
      return data ? data.data.apiKey : null
    }
  },
  created () {
    if (this.receivedShopName) {
      this.beginOAuth(this.receivedShopName)
    }
  }
}
</script>

<style lang="scss" scoped>
.loader-text {
  font-weight: 500;
  font-size: 16px;
}
</style>
